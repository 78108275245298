export const setMeta = (seo: object) => {
    const config = useRuntimeConfig();
    const meta: object = {
        title: seo.metaTitle,
        ogTitle: seo.metaTitle,
        description: seo.metaDescription,
        ogDescription: seo.metaDescription,
        keywords: seo.keywords,
    };

    if (seo?.metaImage !== undefined) {
        meta.ogImage = config.public.mediaBaseUrl + seo.metaImage.data.attributes.formats.thumbnail.url;
    }

    if (seo?.canonicalURL !== undefined && seo.canonicalURL) {
        meta.canonical = seo.canonicalURL;
    }

    if (seo?.metaRobots !== undefined && seo.metaRobots) {
        meta.robots = seo.metaRobots;
    }

    useSeoMeta(meta);
}